/* SignInWithGoogle.css */

/* Styles for the Google logo image */
.google-logo-image {
    width: 24px; /* Set the desired width */
    height: 24px; /* Set the desired height */
    margin-right: 10px; /* Adjust the margin as needed */
  }
  
  /* Styles for the Google Sign-In button */
  .google-sign-in-button {
    display: flex;
    align-items: center;
    background-color: #fff; /* Button background color */
    border: 1px solid #ccc; /* Button border */
    padding: 10px 20px; /* Button padding */
    border-radius: 4px; /* Button border radius */
    cursor: pointer;
    font-size: 16px;
    color: #333; /* Button text color */
    text-align: left;
    transition: background-color 0.3s, color 0.3s;
  }
  
  /* Hover effect */
  .google-sign-in-button:hover {
    background-color: #eee; /* Darker background color on hover */
    color: #000; /* Text color changes on hover */
  }
  