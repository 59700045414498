.delete-text{
    color: red;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin-top: 1rem;
}

@keyframes blink {
    0%, 60%, 100% {
        opacity: 1;
    }
    30%, 50% {
        opacity: 0;
    }
}
  
  /* Animation delays for each letter */
  .span1 {
    animation: wave-blink 2s infinite;
    animation-delay: 0s;
  }
  .span2 {
    animation: wave-blink 2s infinite;
    animation-delay: 0.5s;
  }
  .span3 {
    animation: wave-blink 2s infinite;
    animation-delay: 1s;
  }

  @keyframes wave-blink {
    0%, 60%, 100% {
        opacity: 1;
    }
    30%, 50% {
        opacity: 0;
    }
    /* 0%,40%,100% {
        transform: translateY(0)
    }
    20% {
        transform: translateY(20px)
    } */
}
  
.profile-main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 2em 1em;
    padding: 1em 1em;
    border-radius: 10px;
    transition-duration: 0.2s;
}
.profile-main:hover{
    background-color: red;
    transition-duration: 1s;
}

.loading-container {
    height: 300px;
}