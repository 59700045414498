:root {
    --navbar-height: 74px;
    --collapsed-navbar-height: 150px; /* Collapsed navbar height */
}

#logo {
    margin: 0.5rem 1rem;
    height: 2rem;
    width: 2rem;
}

.navbar {
    position: sticky;
    justify-content: space-between;
    min-width: 295px;
    z-index: 999;
    align-items: center;
}

.btn-outline-dark {
    background-color: #D8737F;
    color: #ffffff;
    margin-right: 10px;
}

.btn-outline-dark:hover {
    background-color: #000000;
    color: #fff;
}

/* Navbar link styles */
.navbar-dark .navbar-nav {
    text-align: center;
}

.navbar-dark .navbar-nav .nav-link {
    color: #fff; /* Link color */
    margin: 0 1rem;
}

.navbar-dark .navbar-nav .nav-link:hover {
    color: #D8737F; /* Hover link color */
}

#button-center {
    margin-left: auto;
    margin-right: auto;
}

.sign-button {
    display: contents;
}

.left-nav,
.right-nav,
.change-right-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* Responsive styles */
@media (max-width: 430px) {
    .brand-text {
        display: none;
    }
    .navbar-unreduced {
        display: none;
    }
}

@media (min-width: 430px) {
    .brand-text {
        display: flex;
    }
    .navbar-reduce {
        display: none;
    }
    .navbar-unreduced {
        display: flex;
    }
}

/* Dropdown styles */
.dropdown-toggle::after {
    display: none;
}

.dropdown-item {
    display: flex;
    justify-content: center;
    padding: 0.5em;
    border-bottom: none;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: transparent;
    color: #ffffff;
}

.dropdown-menu {
    padding: 0;
    margin: 14px 0 0;
}
