:root {
  --primary-color: ##308695 ;
  --secondary-color: #D45769;
  /* --accent-color: #FCBB6D; */
  --accent-color: #e5b073;
  --accent-color-2: #D4CFC9;
  /* --form-filled-color: #2A9D8F; */
  --form-filled-color: #458777;
  --radio-box-color: #414953;
  --form-card-header: #458777;
  --form-submitted-color: #4ec065;
}

.type-1:hover {
    border-radius:1px;
    border: 1px solid #aaa;
    transition: .3s border-color;
  }

.type-1 {
  border-radius:1px;
  border: 1px solid #eee;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.user-alert{
  position: absolute;
  top: 50%;
}

.form{
  margin: auto;
  width: -webkit-fill-available;
  max-width: 1000px;
}

.submit-button-container{
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.review-text-container {
  /* display: flex; */
    padding: 20px;
    /* text-align: center; */
    /* margin: 40px; */
    border-radius:10px;
  }

.list-container {
    padding: 0;
    margin-right: 0px;
    border-radius: 10px;
    text-align: -webkit-center;
    margin-left: 0px;
    text-shadow: 1px 2px 1px #000000;
  }

.horizontal-list {
    color: var(--accent-color);
    display: flex;
    padding: 0px;
    justify-content: center;
    width: 100%;
  }

  .radio-box-container {
    width: 80%;
    background-color: #aaa;
    padding: 10px;
    margin: 10px;
    border-radius:10px;
    display: inline-block;
  }

  .radio-box-header{
    background-color: var(--secondary-color);
    color: var(--accent-color);
    text-align: center, !important ;
    font-size: 14px;
    font-weight: bold;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between; /* Space out the title and the toggle */
    /* ...other styles */
}


.radio-input {
  width: 20px;
  height: 20px;
}
.radio-box:hover {
    border: 3px solid var(--accent-color);
    transition: 0.5s border-color;
  }

.radio-box-container.selected .radio-box-header {
  background-color: var(--form-filled-color); /* Adjust the color as needed */
  transition: background-color 0.5s;
}


  
.radio-label {
  align-items: center;
}

.radio-box {
    color: var(--accent-color-2);
    border: 3px solid var(--accent-color-2);
    background-color: #414953;
    padding: 10px;
    display: flex;
    font-size: 14px;
    justify-content: center;
    margin: 10px;
    border-radius:10px;
}  

.radio-list{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px;
}

.basic-form-container{
  background-color: var(--form-filled-color);
  border-radius:10px;
}

.basic-form-container-name-email{
  background-color: var(--form-filled-color);
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.date-container{
  justify-content: space-evenly;
}

.address-container{
  background-color: var(--form-filled-color);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name-box{
  background-color: var(--form-filled-color);
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 100%;
}

.address-box{
  background-color: var(--form-filled-color);
  border-radius: 10px;
  display: flex;
  width: 100%;
}

.name{
  background-color: var(--form-filled-color);
  padding: 10px;
  margin: 10px;
  border-radius:10px;
}

.date-name{
  background-color: var(--form-filled-color);
  padding: 10px;
  margin: 10px;
  border-radius:10px;
  min-width: 100px;
  max-width: 100px;
}

.address{
  background-color: var(--form-filled-color);
  padding: 10px;
  margin: 10px;
  border-radius:10px;
}

/* //////////////////////////////////// */

@media only screen and (max-width: 780px) {
  .basic-form-container-name-email {
    flex-direction: column;
  }
  .name-box{
    flex-direction: column;
  }
  .date-container{
    background-color: var(--form-filled-color);
    display: flex;
    align-items: center;
  }
  .horizontal-list{
    flex-direction: column;
  }
}

@media (min-width: 480px) {
  .date-start-container-box{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .date-end-container-box{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .date-start-container-box{
    flex-direction: column;
    align-items: center;

  }
  .date-end-container-box{
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width: 600px) {
  .date-container{
    display: flex;
    flex-direction: row;
  }
}
@media (max-width: 600px) {
  .date-container{
    flex-direction: column;
  }
  .date-input{
    width: 75%;
  }
}

.card-header {
  background-color: var(--secondary-color);
  color: var(--accent-color);
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  padding: 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 40px rgba(0, 0, 0, 1.2); /* Add box shadow */
  z-index: 0;
}

.card-title{
  font-size: 20px;
  font-weight: bold;
  padding: 0px;
  margin: 0 5px;
  border-radius:10px;
  display: flex;
  justify-content: center;
}

.card-body {
  overflow: hidden;
  max-height: 0;
  width: 100%;
  transition: max-height;
  justify-content: center;
  transition: max-height 0.2s ease;
  padding: 0%;
}

.expanded{
  max-height: 3000px; /* Adjust the maximum height as needed */
  transition: max-height 1s ease;
  width: 100%;
  min-width: 295px;
  overflow: hidden;
}

.not-expanded{
  max-height: 0px; /* Adjust the maximum height as needed */
  transition: max-height 0.5s ease;
  width: 100%;
  min-width: 295px;
  overflow: hidden;
}

.card-footer {
  background-color: var(--secondary-color);
  color: var(--accent-color);
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  padding: 10px;
  margin: 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 40px rgba(0, 0, 0, 1.2); /* Add box shadow */
  z-index: 0;
  width: 50%;
  border : 1px solid var(--accent-color);
}

.card-footer:last-child {
  border-radius: 10px;
}

.card{
  min-width: 295px;
  min-height: 110px;
  background-color: var(--form-filled-color);
  color: var(--accent-color);
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin: 10px;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 10px;
  align-items: center;
  width: 85%;
}

.card:hover .card-header:not(.expanded) {
  width: 66%;
  transition: all 0.5s ease
}

.card-header {
  min-width: 150px;
  background-color: var(--secondary-color);
  width: 75%;
  margin: 20px 0px;
  transition: all 0.5s ease;
}

.card-header:first-child {
border-radius: 5px;
}
/* //////////////////////////////////// */

.previous-submissions-dropdown-select{
  padding: 10px;
  /* margin: 10px; */
  border-radius:10px;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit-message {
  background-color: var(--form-submitted-color);
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
  min-width: 295px;
  width: auto;
  height: 150px;
  z-index: 9999;
  text-align: center;
  border: solid;
}

.submit-message-container{
  background-color: var(--form-submitted-color);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.submit-button {
  width: 50%;
  font-size: 20px;
  font-weight: 400;
  margin: 10px;
  transition: all 0.5s ease;
}

.submit-button:hover {
  background-color: var(--accent-color);
  transition: all 0.5s ease;
  cursor: pointer;
}

.submit-message-close {
  margin: 10px;
  margin-top: 0px;
  border-radius: 5px;
  transition: all 0.5s ease;
}

.submit-message-close:hover {
  background-color: var(--accent-color);
  transition: all 0.5s ease;
}


.date-start-container-box{
  background-color: var(--form-filled-color);
  border-radius: 10px;
  width: 100%;
}

.date-end-container-box{
  background-color: var(--form-filled-color);
  border-radius: 10px;
  width: 100%;
}

textarea{
  width: calc(100% - 1em);
  resize: none;
}

.character-counter{
  position:absolute;
  bottom: 5px;
  right: 7px;
  font-size: 15px;
}

.additional-info-container{
  position:relative;
  display:inline;
}

.address-input::-webkit-calendar-picker-indicator {
  display:none !important;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #FFFFFF;
  color: #000000;
  font-size: 16px; /* Adjust font-size if necessary */
  border: none; /* or 1px solid #000 if you want a border */
  cursor: help;
  position: relative;
  top: 0px; /* Adjust if necessary to align with the label */
  text-shadow: none;
}


.tooltip-box {
  position: fixed;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  height: auto;
  width: 300px;
  max-width: 300px;
  min-width: 100px; 
  overflow-wrap: normal;
  visibility: visible;
  transition: visibility 0.2s, opacity 0.2s ease;
}

.tooltip-box:after {
  content: '';
  position: absolute;
  left: 100%;
  top: 0;
  width: 250px;
  height: 100%;
  pointer-events: none;
}

.tooltip-box {
  visibility: visible;
}

.tooltip-container:hover .tooltip-box {
  visibility: visible;
  opacity: 1;
}

.card-header.all-selected {
  background-color: var(--form-card-header) !important;
}

.name-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.label-and-tooltip {
  width: 100%; /* Ensures the label and tooltip span the full width */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px; /* Adjust this value to create space between label and input */
}

.address-input {
  width: 75%; /* Adjust the width as needed */
  padding: 10px; /* Adjust padding as needed */
  margin: 8px 10px;
  border-radius: 5px;
}

.email-input, .name-input {
  width: 75%; /* Adjust the width as needed */
  padding: 10px; /* Adjust padding as needed */
  margin: 8px 10px;
  border-radius: 5px;
}

.date-input{
  padding: 10px; /* Adjust padding as needed */
  margin: 8px 10px;
  border-radius: 5px;
}

.radio-box.disabled {
  opacity: 0.5; /* Make radio buttons appear greyed-out */
  pointer-events: none; /* Prevent interaction with the radio buttons */
}

.card-header.completed {
  background-color: limegreen; /* Example color, adjust as needed */
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Adjust the position and size of the N/A toggle switch */
.na-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 430px) {
  .na-toggle {
    flex-direction: column-reverse; /* Reversing the order to move the toggle to the right */
  }
}
@media (min-width: 430px) {
  .na-toggle {
    flex-direction: row-reverse; /* Reversing the order to move the toggle to the right */
  }
}


.switch {
  position: relative;
  display: inline-block;
  width: 40px; /* Decrease the width to make the switch smaller */
  height: 24px; /* Adjust the height as needed */
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 24px; /* Ensure the slider has rounded ends */
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

/* Adjust the position of the N/A label */
.na-label {
  margin-right: 5px; /* Add space between the label and the switch */
}

label{
  margin-bottom: 0px;
}

.click-to-collapse{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 20px;
}