.review-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 100%;
    height: 100%;
    padding: 20px;
}

.review-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the start of the flex container */
    justify-content: center;
    width: 120%;
    margin-top: 10px;
    margin-bottom: 10px;
    min-width: 200px;
    height: 100%;
    padding: 20px;
    border-radius: 10px;
    background-color: #1a1717;
    color: #fff;
    animation: fadeIn-scaleOut 0.5s;
}

@keyframes fadeIn-scaleOut {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.review-item { /* Added this class */
    display: flex;
    justify-content: space-between; /* Space between the descriptor and info */
    width: 100%; /* Take full width to align info to the right */
    margin-bottom: 10px; /* Optional: add some space between each review item */
}

.descriptor {
    font-size: 1.1rem;
    font-weight: bold;
    /* Removed margin to apply it on .review-item */
}

.info {
    font-size: 1rem;
    color: red;
    text-align: right; /* Align text to the right */
    /* Removed margin to apply it on .review-item */
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 1em 5em;
}

.dropdown-item {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #ccc; /* Optional */
}

.dropdown-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.dropdown-content {
    padding: 10px;
    background-color: #f9f9f9;
    border-left: 2px solid #ddd; /* Optional */
    margin-top: 5px;
}
.dropdown-item:hover {
    background-color: #e0e0e0; /* Light grey color on hover */
}

@media (max-width: 430px) {
  .review-card {
    width: 100%;
  }
}
@media (min-width: 430px) {
  .review-card {
  }
}