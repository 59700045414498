.leaflet-container {
    width: 100%;
    /* height: calc(100vh - var(--navbar-height)); */
    z-index: 1;
  }

  @media (max-width: 430px) {
    .leaflet-container {
      height: calc(100vh - 74px);
    }
  }
  @media (min-width: 430px) {
    .leaflet-container {
      height: calc(100vh - 104px);
    }
  }