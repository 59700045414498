
   .map {
    position: relative;
    z-index: 1;
   }

  .leaflet-control-geosearch {
    display: flex;
    height: fit-content;
    align-items: center;
    min-height: 30px;
    width: min-content;
    position: absolute;
    top: 20px;
    left: 50px;
    z-index: 999;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .leaflet-geosearch-bar {
    position: relative;
    display: block;
    height: auto;
    width: 400px;
    max-width: calc(100% - 120px);
    cursor: auto;
    z-index: 1000;
}

  .leaflet-control-geosearch .results.active {
    padding: 8px 0;
    border-top: 1px solid #c6c6c6;
  }

  .leaflet-control-geosearch .results>* {
    line-height: 24px;
    padding: 0 8px;
    border: 1px solid transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.leaflet-control-geosearch .results.active :hover {
  background-color: rgb(202, 202, 202);
  cursor: pointer;
}

  .leaflet-control-geosearch ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .leaflet-control-geosearch form {
    padding: 0 8px;
  }

  .leaflet-touch .leaflet-geosearch-bar form {
    border: 2px solid rgba(0,0,0,.2);
    box-shadow: none;
  }

  .leaflet-geosearch-bar form {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    border-radius: 4px;
  }

  .leaflet-touch .leaflet-geosearch-bar form input {
    height: 30px; 
  }

  .leaflet-control-geosearch button.reset {
    color: #000;
    font-weight: 700;
    line-height: 30px;
    position: absolute;
    padding: 0 8px;
    right: 0;
    top: 0;
    cursor: pointer;
    border: none;
    text-decoration: none;
    background-color: #fff;
    border-radius: 0 4px 4px 0;
  }

  .leaflet-control-geosearch button:focus{
    outline: none;
  }

  .leaflet-control-geosearch button:hover{
    background-color: rgb(202, 202, 202);
  }

  .leaflet-geosearch-bar form input {
    min-width: 100%;
    width: 100%;
  }
  
  .leaflet-control-geosearch form input {
    outline: none;
    margin: 0;
    padding: 0;
    font-size: 12px;
    border: none;
    border-radius: 0 4px 4px 0;
    text-indent: 8px;
}

.leaflet-control-geosearch form {
  background-color: #fff;
  background-clip: padding-box;
  z-index: 999;
  height: auto;
  margin: 0;
  padding: 0 8px;
  box-shadow: 0 1px 5px rgb(0 0 0 / 65%);
}