/* Pulsing and rotating gradient styling */
.loading-gradient {
    width: 150px;
    height: 150px;
    background: linear-gradient(135deg, #4e54c8, #8f94fb);
    border-radius: 50%;
    animation: pulse-rotate 1.5s infinite ease-in-out;
}

/* Centering the loader using Flexbox */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw; /* Full width of the viewport */
    min-width: 295px;
}

@media (max-width: 430px) {
    .loading-container {
      height: calc(100vh - 74px);
    }
  }
@media (min-width: 430px) {
    .loading-container {
      height: calc(100vh - 104px);
    }
  }

/* Keyframes for the pulsing animation */
@keyframes pulse-rotate {
    0% {
        transform: scale(1) rotate(0deg);
        opacity: 1;
    }
    50% {
        transform: scale(1.2) rotate(180deg);
        opacity: 0.7;
    }
    100% {
        transform: scale(1) rotate(360deg);
        opacity: 1;
    }
}
