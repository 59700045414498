/* Add a full-screen background image */
.login-background {
    background-image: url('../../public/Home.png'); /* or use the correct path to the image */
    background-size: cover; /* Make sure the image covers the entire area */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Avoid repeating the image */
    height: calc(100vh - 74px); /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 295px;
  }
  @media (max-width: 430px) {
    .login-background {
      height: calc(100vh - 74px);
    }
  }
  @media (min-width: 430px) {
    .login-background {
      height: calc(100vh - 104px);
    }
  }

  .verify {
    background-color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 2em 1em;
    padding: 1em 1em;
    border-radius: 10px;
    transition-duration: 0.2s;
  }

  .verify-email-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 2em 1em;
    padding: 1em 1em;
    border-radius: 10px;
    transition-duration: 0.2s;
  }

  .verify-button{
    background-color: #45a049;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 10px;
    padding: 10px 24px;
    transition: background-color 0.3s;
  }

  .verify-button:hover{
    background-color: #3CAF50;
    transition: background-color 0.3s;
  }