.sign-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(108, 172, 124);
    border-radius: 10px;
    box-shadow: 1px 1px 10px 1px rgb(0, 0, 0);
    perspective: 1000px; 
}

.sign-card-body-input{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  box-shadow: 1px 1px 10px 1px rgb(0, 0, 0);
  border-radius: 10px;
}

.sign-card-body-input-submit{
  margin-bottom: 10px;
}

.sign-card {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(108, 172, 124);
    border-radius: 10px;
    box-shadow: 1px 1px 10px 1px rgb(0, 0, 0);
    perspective: 1000px;
    z-index: 9999;
}
  
  /* .form-container {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d; 
    transition: transform 0.5s;
  } */
  
  .show-sign-up {
    transform: rotateX(180deg); /* Rotate to show Sign Up form */
    transform: rotateY(360deg);
}
  
  /* Add any other styling you need for your forms */
  
.sign-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Add any other styling you want for your "sign-form" here */
    /* padding: 20px; */
    /* width: 200px; */
}

.sign-form-text{
    /* Add any other styling you want for your "sign-form-text" here */
    font-size: 1.1rem;
    text-align: center;
    margin: 0px;

}

.sign-card-body-title{
    /* Add any other styling you want for your "sign-card-body-title" here */
    font-size: 1.5rem;
    text-align: center;
    margin: 20px;
}

/* Sign.css */
  
.sign-card-flip {
  background-color: transparent;
  margin: auto;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  transition: transform 0.5s;
}

.sign-card-flip-inner {
  /* background-color: rgb(108, 172, 124); */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  transition: 0.8s;
}

/* .sign-card-flip:hover .sign-card-flip-inner {
  transform: rotateY(180deg);
} */

.flip{
  transform: rotateY(180deg);
}

.sign-card-flip-front, .sign-card-flip-back {
  position: absolute;
  display: flex; 
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.sign-card-flip-back {
  flex: content;
  background-color: rgb(108, 172, 124);
  transform: rotateY(180deg);
  border-radius: 20px;
}

.form-group{
  text-align: center;
  /* size: 10px; */
}

#country{
  max-width: 202.67px;
}
option {
  max-width: 202.67px;
}

/* select {
  width: 200px !important;
  max-width: 200px !important;
}

select option {
  width: 200px !important;
} */

.form-control{
  /* width: fit-content; */
  padding: 5px 10px;
  margin: auto;
  border-radius: 10px;
}

.sign-in-container, .sign-up-container{
  border-radius: 20px;
  background-color: rgb(17, 105, 76);
  color: aliceblue;
  width: 300px;
}

.close {
  background-color: #007bff; /* Background color */
  color: #fff; /* Text color */
  padding: 10px 20px; /* Padding for the button */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Change cursor to pointer on hover */
  font-size: 16px; /* Font size */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition on hover */
}

/* Hover effect */
.close:hover {
  background-color: #0056b3; /* Darker background color on hover */
  color: #fff; /* Text color remains white on hover */
}

.toggle-button{
  transform: scale(70%);
  margin: 5px;
}

.alert{
  width: 202.67px;
  pointer-events: none
}

.link{
  color: #73faff !important;
}

.link:hover{
  color: black !important;
  cursor: pointer;
}

.revert-sign{
  margin: 10px;
}

.verification-box{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 1px 1px 10px 1px rgb(0, 0, 0);
  border-radius: 10px;
}
